import React from 'react'

function RNWBanner() {
  const banner = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Fpm-rnw-banner-mob.png?alt=media&token=7d306bc8-8fe7-4a27-88a7-38032f9d0c7c';
  const bannerDesk = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/2024%2Fbanners%2Fpm-rnw-banner-desk.png?alt=media&token=1a8775b9-7aba-454e-934d-3903d6e30b3d';
  const altText = 'RATT\'#\'WHORE';

  return (
    <div className='items-container__banner'>
        <div className='items-container__banner-image--portrait items-container__banner-image--rnw'>
          <img src={banner} width='2000' height='3000' alt={altText} />
        </div>
        <div className='items-container__banner-image--landscape items-container__banner-image--rnw'>
          <img src={bannerDesk} width='2000' height='2000' alt={altText} />
        </div>
    </div>
  )
}

export default RNWBanner
