import React from 'react'
import { NavLink } from 'react-router-dom'
import { footerTextData } from '../../data/footer'

function Footer({ locale, changeLocale }) {
  return (
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__locale'>
        {
          locale === 'ru' ?
          <span onClick={changeLocale('en')}>
            switch to english
          </span> :
          <span onClick={changeLocale('ru')}>
            русская версия
          </span>
        }
        </div>
        <div className='footer__info'>
          <div>
            <div className='footer__info-item'>
              <NavLink to='/delivery-and-payment' className='footer__info-link link' activeClassName='footer__info-link--active'>
                {footerTextData[locale].delivery}
              </NavLink>
            </div>
            <div className='footer__info-item'>
              <NavLink to='/return-and-exchange' className='footer__info-link link' activeClassName='footer__info-link--active'>
                {footerTextData[locale].return}
              </NavLink>
            </div>
            <div className='footer__info-item'>
              <NavLink to='/contacts' className='footer__info-link link' activeClassName='footer__info-link--active'>
                {footerTextData[locale].contacts}
              </NavLink>
            </div>
          </div>
        </div>
        <div className='footer__copyright'>
          <a href='https://indigoflow.space' target='_blank' rel='noopener noreferrer'>© 2025 INDIGO FLOW</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
